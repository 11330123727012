import * as React from 'react';
import { LayoutDashboard } from '@/components/layouts/dashboard/dashboard';
import {
	HeadingSize,
	TextHeading,
} from '@vintro/components/vintro/typography/typography';
import Button, { ButtonType } from '@vintro/components/vintro/button';
import Link from 'next/link';
import { LayoutStyledAuth } from '@/components/layouts/auth/auth';

export const FourOhFour = () => {
	const [displayState, setDisplayState] = React.useState('default');

	React.useEffect(() => {
		if (window.location.href.includes(`${window.location.origin}/dashboard`)) {
			setDisplayState('dashboard');
		}
	}, []);

	return (
		<>
			{displayState === 'dashboard' ? (
				<LayoutDashboard>
					<div className="grid grid-cols-12 gap-4">
						<div className="col-span-12">
							<div className="rounded-lg bg-white shadow">
								<div className="flex flex-col items-center space-y-8 py-8 px-6">
									<TextHeading
										className={'inline-flex'}
										headingSize={HeadingSize.h5}
									>
										404 - Page Not Found
									</TextHeading>

									<Link href={`/dashboard`}>
										<Button type={ButtonType.secondary}>
											Return to Dashboard
										</Button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</LayoutDashboard>
			) : (
				<LayoutStyledAuth>
					<div className="grid grid-cols-12 gap-4">
						<div className="col-span-12 flex flex-col items-center space-y-8">
							<TextHeading
								className={'inline-flex'}
								headingSize={HeadingSize.h5}
							>
								404 - Page Not Found
							</TextHeading>
							<Link href={`/`}>
								<Button type={ButtonType.secondary}>Return to Login</Button>
							</Link>
						</div>
					</div>
				</LayoutStyledAuth>
			)}
		</>
	);
};

export default FourOhFour;
